/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Card, Col, Flex, Input, Modal, Row, Pagination } from "antd";
import DonutChart from "../components/chart/DonutChart";
import { SearchOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { CloudUploadOutlined } from "@ant-design/icons";
import { FileContext } from "../context/fileContext";
import { supabase } from "../components/supaClient";
import { toast } from "react-toastify";
import { trackNewSignup } from '../components/custompixel';
import { AlertCircle } from "lucide-react";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 300,
    width: 300,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 18,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function Home() {
  const [affiliateId,setAffiliate] =  useState(""); 
  const { CSVReader } = useCSVReader();
  const {
    acceptedFile,
    setFileData,
    setFileName,
    setAcceptedFile,
    user,
    setUser,
  } = useContext(FileContext);
  const [searchInput, setSearchInput] = useState("");
  const [cardsOnPage,setCardsChange] = useState(10);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [zoneHover, setZoneHover] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMigrationModalOpen, setIsMigrationModalOpen] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const [totalData, setTotalData] = useState(0);
  const showModal = () => {
    setIsMigrationModalOpen(true);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
    navigate("/upload");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMigrationOk = () => {
    setIsMigrationModalOpen(false);
    window.open("https://app.readytosend.ai", "_blank");
  };
  
  const handleMigrationCancel = () => {
    setIsMigrationModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const { data: tableLength } = await supabase
        .from("full_bulk_validation_api")
        .select("*");
      setTotalData(tableLength.length);
    })();
  }, []);
  const trackSigninsaffiliate= async (id,email) => {

    try {
      const body = {
            "partner": id,
            "key": email,
            "email": email,
            "name": "Customer"
      };
      console.log(body)
      const response = await fetch("https://api.verifymagically.com/webhook/partner-customer-adding", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin":"*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      // res.json({message: 'Success'});

    } catch (err) {
      // res.json({message: err.message});
    } 
  };

  const handleNewUserSignup = async (userEmail) => {
    try {
      await fetch(
        `${process.env.REACT_APP_NOBOUNCE_WEBHOOK_URL}/new-user-verifymagically`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${process.env.REACT_APP_NOBOUNCE_AUTHORIZATION}`,
          },
          body: JSON.stringify({
            "user-email": userEmail,
          }),
        }
      );
    } catch (err) {
      return toast.error(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      console.log(session);
      const { data: user } = await supabase.from("users").select("*");
      
      if (user.length > 0) {
        setUser({
          credits: user[0]?.credits,
          email: session.user.email,
          uniqueUserID: user[0]?.["user id"],
          uniqueAPI: user[0]?.["user api key"],
          stripeID: user[0]?.["stripe customer id"],
        });
      } else {
        await handleNewUserSignup(session.user.email);
        if(session.user.user_metadata?.affiliate_id!=null && session.user.user_metadata?.affiliate_id!=""){
          setAffiliate(session.user.user_metadata?.affiliate_id);
          await trackSigninsaffiliate(session.user.user_metadata?.affiliate_id,session.user.email);
        }
        const { data: user } = await supabase.from("users").select("*");
        if (user) {
          setUser({
            credits: user[0]?.credits,
            email: session.user.email,
            uniqueUserID: user[0]?.["user id"],
            uniqueAPI: user[0]?.["user api key"],
            stripeID: user[0]?.["stripe customer id"],
          });
          const userData = {
            credits: user[0]?.credits,
            email: session.user.email,
            uniqueUserID: user[0]?.["user id"],
            uniqueAPI: user[0]?.["user api key"],
            stripeID: user[0]?.["stripe customer id"],
          };
          trackNewSignup(userData);
        }
      }
    })();
  }, []);

  const fetchFileData = async (empty=false) => {
    if(empty===true){
      setData([]);
    }

    const { data: tableLogs, error } = await supabase
      .from("full_bulk_validation_api")
      .select("*")
      .order("created_at", { ascending: false })
      .range((page - 1) * cardsOnPage, page * cardsOnPage - 1);

    setData(tableLogs);
    if (error) {
      toast.error("Could not fetch Data");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchFileData(false);
    }, 15000);

    return () => clearInterval(interval);
  }, [page,cardsOnPage]);

  useEffect(() => {
    fetchFileData(true);
  }, [page,cardsOnPage]);

  useEffect(() => {
    (async () => {
      if (searchInput) {
        const { data: filteredData, error } = await supabase
          .from("full_bulk_validation_api")
          .select("*")
          .ilike("filename", `%${searchInput}%`);

        setSearchedData(filteredData);
      } else {
        setSearchedData(data);
      }
    })();
  }, [searchInput, data]);
  
  return (
    <>
      <div className="pb-6">
        <Modal
          title="Upload Files"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="submit" onClick={handleOk} disabled={!acceptedFile}>
              Submit
            </Button>,
          ]}
          width={600}
          height={600}
        >
          <div className="w-[550px] h-[500px] flex flex-col items-center justify-center">
            <CSVReader
              onUploadAccepted={(results) => {
                setFileData(results.data);
                setZoneHover(false);
                setFileUploaded(true);
                handleOk();
              }}
              onDragOver={(event) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                setZoneHover(false);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
                Remove,
              }) => (
                <>
                  {setAcceptedFile(acceptedFile)}
                  <div
                    {...getRootProps()}
                    className="cursor-pointer min-h-[250px] min-w-[250px] max-h-[500px] max-w-[500px]  flex items-center justify-center"
                  >
                    {acceptedFile ? (
                      <>
                        {setFileName(acceptedFile?.name)}
                        <div style={styles.file}>
                          <div style={styles.info}>
                            <span className="text-xs bg-transparent">
                              {formatFileSize(acceptedFile.size)}
                            </span>
                            <span className="text-xs bg-transparent">
                              {acceptedFile.name}
                            </span>
                          </div>
                          <div style={styles.progressBar}>
                            <ProgressBar />
                          </div>
                          <div
                            {...getRemoveFileProps()}
                            style={styles.remove}
                            onMouseOver={(event) => {
                              event.preventDefault();
                              setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                            }}
                            onMouseOut={(event) => {
                              event.preventDefault();
                              setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                            }}
                          >
                            <Remove color={removeHoverColor} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <Card
                        className="flex items-center border-none justify-center w-full h-full flex-col rounded-2xl bg-white"
                        title={
                          <span className="flex flex-col items-center justify-center p-8 gap-14">
                            <h1 className="font-bold text-gray-700 text-2xl">
                              Upload CSV File
                            </h1>
                            <span className="text-6xl text-[#6e58f0]">
                              <CloudUploadOutlined />
                            </span>
                            <div className="text-sm font-semibold text-gray-700 text-center">
                              Select a CSV file to import <br />
                              or
                              <br />
                              Drag & Drop CSV file here
                            </div>
                          </span>
                        }
                      >
                        <span className="text-gray-700 text-sm">
                          Upload you CSV files to import leads
                        </span>
                      </Card>
                    )}
                  </div>
                </>
              )}
            </CSVReader>
          </div>
        </Modal>
        
        <Modal
          title="We've Moved!"
          open={isMigrationModalOpen}
          onOk={handleMigrationOk}
          onCancel={handleMigrationCancel}
          footer={[
            <Button 
              key="visit" 
              type="primary" 
              onClick={handleMigrationOk}
              className="bg-blue-600 hover:bg-blue-700"
            >
              Visit Ready to Send
            </Button>,
          ]}
        >
          <div className="py-4 flex flex-col items-center">
            <AlertCircle className="h-12 w-12 text-yellow-400 mb-4" />
            <p className="text-center mb-4">
              We have migrated Verify Magically to our new platform Ready to Send.
            </p>
            <p className="text-center text-sm text-gray-700">
              Please contact our support team for transferring your credits to Ready to Send.
            </p>
          </div>
        </Modal>
        
        <Flex horizontal="true" justify="space-between">
          <Input
            className="w-[300px]"
            placeholder="Type here..."
            onChange={(e) => setSearchInput(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <Flex className="gap-3">
          <Button
            className="border border-blue-500 bg-blue-500 text-white h-[50px]"
            onClick={() => showModal()}
          >
            Upload
          </Button>
          </Flex>
        </Flex>
        <br />
        <br />
        {searchedData.length === 0 ? (
          <div className="w-full h-[70vh] flex items-center justify-center text-4xl text-gray-700 font-semibold">
            Let's get started by uploading a file !
          </div>
        ) : (
          <Row gutter={[24, 0]}>
            {searchedData.map((item, index) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className="mb-6"
                key={index}
              >
                <Card bordered={false} className="h-full">
                  <DonutChart data={item} />
                </Card>
              </Col>
            ))}
          </Row>
        )}
        {totalData !== 0 && searchInput === "" && (
          <div className="w-full flex items-center justify-center">
            <Pagination
              defaultCurrent={1}
              current={page}
              total={totalData}
              pageSize={cardsOnPage}
              onChange={(page, pageSize) => {
                setPage(page) 
                setCardsChange(pageSize)
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
